export default {
  async insert (Vue, formData, idpresupuestocli) {
    const resp = await Vue.$api.call(
      'presupuestoCuota.insert',
      {
        values: {
          idpresupuestocli,
          idconcepto_cuota: formData.concepto_cuota.idconcepto_cuota,
          iva: formData.iva,
          recargo: formData.recargo,
          precio_mes: formData.precio_mes,
          idperiodo_cuota: formData.periodo_cuota.idperiodo_cuota,
          unidades: formData.unidades,
          dto: formData.dto,
          opcional: formData.opcional,
          inf_comercial: formData.inf_comercial,
          activacion_automatica: formData.activacion_automatica,
          meses_permanencia: formData.meses_permanencia,
          dia_mes_limite: formData.dia_mes_limite,
          meses_desde_inst: formData.meses_desde_inst,
          dia_mes_cuota: formData.dia_mes_cuota,
          meses_duracion: formData.meses_duracion,
          estado: 1,
        },
      },
    )
    return resp.data.result.dataset[0]
  },
}
